// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rectangle {
  border: none;
  padding: 20px;
  border-radius: 15px;
  background-color: rgba(43, 51, 65, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 0px;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

.rectangle-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Rectangle.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uCAAuC;EACvC,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,eAAe;EACf,mCAA2B;UAA3B,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;AACrB","sourcesContent":[".rectangle {\n  border: none;\n  padding: 20px;\n  border-radius: 15px;\n  background-color: rgba(43, 51, 65, 0.8);\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  margin-top: 0px;\n  backdrop-filter: blur(10px);\n}\n\n.rectangle-content {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
