// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.statistic-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  color: #fff;
  margin-top: 100px;
  padding-bottom: 20px;
  width: 100%;
  height: 80vh;
  overflow-y: auto;
}

.statistic-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  gap: 20px;
  margin-top: 20px;
  padding-bottom: 20px;
  flex-grow: 1;
}

.statistic-content > div {
  flex: 1 1 45%;
  min-width: 300px;
}`, "",{"version":3,"sources":["webpack://./src/pages/StatisticPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,6BAA6B;EAC7B,WAAW;EACX,iBAAiB;EACjB,oBAAoB;EACpB,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,uBAAuB;EACvB,eAAe;EACf,WAAW;EACX,iBAAiB;EACjB,SAAS;EACT,gBAAgB;EAChB,oBAAoB;EACpB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB","sourcesContent":[".statistic-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-color: transparent;\n  color: #fff;\n  margin-top: 100px;\n  padding-bottom: 20px;\n  width: 100%;\n  height: 80vh;\n  overflow-y: auto;\n}\n\n.statistic-content {\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  flex-wrap: wrap;\n  width: 100%;\n  max-width: 1200px;\n  gap: 20px;\n  margin-top: 20px;\n  padding-bottom: 20px;\n  flex-grow: 1;\n}\n\n.statistic-content > div {\n  flex: 1 1 45%;\n  min-width: 300px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
