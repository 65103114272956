// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-data-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #27313E;
  padding: 10px 0;
  margin: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/Stats/HeaderData.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;EACzB,eAAe;EACf,YAAY;AACd","sourcesContent":[".header-data-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #27313E;\n  padding: 10px 0;\n  margin: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
