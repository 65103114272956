// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-button {
  margin: 0 15px;
  padding: 10px 20px;
  color: #7C90AF;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: color 0.3s;
  font-size: 25px !important;
}

.filter-button.active {
  color: #f7a69a;
}

.filter-button:hover {
  color: #f7a69a;
}`, "",{"version":3,"sources":["webpack://./src/components/Buttons/FilterButton.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,cAAc;EACd,6BAA6B;EAC7B,YAAY;EACZ,eAAe;EACf,sBAAsB;EACtB,0BAA0B;AAC5B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".filter-button {\n  margin: 0 15px;\n  padding: 10px 20px;\n  color: #7C90AF;\n  background-color: transparent;\n  border: none;\n  cursor: pointer;\n  transition: color 0.3s;\n  font-size: 25px !important;\n}\n\n.filter-button.active {\n  color: #f7a69a;\n}\n\n.filter-button:hover {\n  color: #f7a69a;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
