// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stat-container {
  display: flex;
  flex-direction: column;
  background-color: #2B3341;
  border-radius: 10px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  justify-content: center;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/Stats/StatContainer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,mBAAmB;EACnB,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,gBAAgB;AAClB","sourcesContent":[".stat-container {\n  display: flex;\n  flex-direction: column;\n  background-color: #2B3341;\n  border-radius: 10px;\n  box-sizing: border-box;\n  width: 100%;\n  height: 100%;\n  justify-content: center;\n  overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
