// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  margin-top: 20px;
}

.form-control {
  width: 300px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .container {
    max-width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 970px;
  }
}

.page-container {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;
}

.main-content {
  overflow-y: auto;
  padding: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE,aAAa;EACb,4BAA4B;EAC5B,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf","sourcesContent":[".container {\n  margin-top: 20px;\n}\n\n.form-control {\n  width: 300px;\n  margin-bottom: 10px;\n}\n\n@media (min-width: 768px) {\n  .container {\n    max-width: 750px;\n  }\n}\n\n@media (min-width: 992px) {\n  .container {\n    max-width: 970px;\n  }\n}\n\n.page-container {\n  display: grid;\n  grid-template-rows: auto 1fr;\n  height: 100vh;\n}\n\n.main-content {\n  overflow-y: auto;\n  padding: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
