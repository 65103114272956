// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-badge.repondu {
  background-color: #689752;
  color: white;
  border: none;
  cursor: pointer;
  padding: 1vh 2vw;
  border-radius: 30px;
}

.status-badge.repondu:hover {
  background-color: #3a6723;
  color: #989898;
}

.status-badge.en-attente {
  color: #C09D68;
}

.status-badge.ferme {
  color: #493c3c;
}`, "",{"version":3,"sources":["webpack://./src/components/Stats/StatusBadge.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".status-badge.repondu {\n  background-color: #689752;\n  color: white;\n  border: none;\n  cursor: pointer;\n  padding: 1vh 2vw;\n  border-radius: 30px;\n}\n\n.status-badge.repondu:hover {\n  background-color: #3a6723;\n  color: #989898;\n}\n\n.status-badge.en-attente {\n  color: #C09D68;\n}\n\n.status-badge.ferme {\n  color: #493c3c;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
