// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-title {
  color: rgba(232, 163, 130, 1);

  /* background: linear-gradient(to right, 
  rgba(232, 163, 130, 1) 0%,
  rgba(254, 226, 214, 1) 52%,
  rgba(232, 163, 130, 1) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent; */
}`, "",{"version":3,"sources":["webpack://./src/components/Texts/TitleText.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;;EAE7B;;;;;;;yCAOuC;AACzC","sourcesContent":[".login-title {\n  color: rgba(232, 163, 130, 1);\n\n  /* background: linear-gradient(to right, \n  rgba(232, 163, 130, 1) 0%,\n  rgba(254, 226, 214, 1) 52%,\n  rgba(232, 163, 130, 1) 100%);\n  background-clip: text;\n  -webkit-background-clip: text;\n  color: transparent;\n  -webkit-text-fill-color: transparent; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
