// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filters {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 100%;
}

.filter-container {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  position: relative;
}

.filter {
  font-weight: bold !important;
  transition: color 0.3s ease !important;
  color: #7C90AF !important;
  border: none !important;
  background-color: transparent !important;
  font-size: 18px !important;
  padding: 1vh 2.5vw;
}

.filter:hover,
.filter:focus,
.create-ticket-button:hover {
  color: rgba(232, 163, 130, 1) !important;
    /* background: linear-gradient(to right, 
  rgba(232, 163, 130, 1) 0%,
  rgba(254, 226, 214, 1) 52%,
  rgba(232, 163, 130, 1) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent; */
}

.create-ticket-button {
  background-color: #7c90af !important;
  border: none !important;
  border-radius: 50px !important;
  font-weight: bold !important;
  transition: background-color 0.3s ease, color 0.3s ease !important;
  font-size: 18px !important;
  margin-top: -10px;
}

@media (max-width: 400px) {
  .filter {
    font-size: 10px !important;
    padding: 5px !important;
  }

  .create-ticket-button {
    padding: 5px 10px !important;
    font-size: 10px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Filters/Filters.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,WAAW;AACb;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,4BAA4B;EAC5B,sCAAsC;EACtC,yBAAyB;EACzB,uBAAuB;EACvB,wCAAwC;EACxC,0BAA0B;EAC1B,kBAAkB;AACpB;;AAEA;;;EAGE,wCAAwC;IACtC;;;;;;;yCAOqC;AACzC;;AAEA;EACE,oCAAoC;EACpC,uBAAuB;EACvB,8BAA8B;EAC9B,4BAA4B;EAC5B,kEAAkE;EAClE,0BAA0B;EAC1B,iBAAiB;AACnB;;AAEA;EACE;IACE,0BAA0B;IAC1B,uBAAuB;EACzB;;EAEA;IACE,4BAA4B;IAC5B,0BAA0B;EAC5B;AACF","sourcesContent":[".filters {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 10px;\n  width: 100%;\n}\n\n.filter-container {\n  display: flex;\n  justify-content: center;\n  flex-grow: 1;\n  position: relative;\n}\n\n.filter {\n  font-weight: bold !important;\n  transition: color 0.3s ease !important;\n  color: #7C90AF !important;\n  border: none !important;\n  background-color: transparent !important;\n  font-size: 18px !important;\n  padding: 1vh 2.5vw;\n}\n\n.filter:hover,\n.filter:focus,\n.create-ticket-button:hover {\n  color: rgba(232, 163, 130, 1) !important;\n    /* background: linear-gradient(to right, \n  rgba(232, 163, 130, 1) 0%,\n  rgba(254, 226, 214, 1) 52%,\n  rgba(232, 163, 130, 1) 100%);\n  background-clip: text;\n  -webkit-background-clip: text;\n  color: transparent;\n  -webkit-text-fill-color: transparent; */\n}\n\n.create-ticket-button {\n  background-color: #7c90af !important;\n  border: none !important;\n  border-radius: 50px !important;\n  font-weight: bold !important;\n  transition: background-color 0.3s ease, color 0.3s ease !important;\n  font-size: 18px !important;\n  margin-top: -10px;\n}\n\n@media (max-width: 400px) {\n  .filter {\n    font-size: 10px !important;\n    padding: 5px !important;\n  }\n\n  .create-ticket-button {\n    padding: 5px 10px !important;\n    font-size: 10px !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
