// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-item {
  font-size: 24px;
  margin: 10px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Stats/StatsDetails.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,cAAc;AAChB","sourcesContent":[".detail-item {\n  font-size: 24px;\n  margin: 10px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
