// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-selector-container {
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.filter-title {
  margin: 0 10px;
  flex: 1 1;
  text-align: left;
}

.filter-selector-container select {
  padding: 5px 10px;
  font-size: 16px;
  background-color: #ffffff !important;
  border: none;
  border-radius: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Filters/FilterSelector.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,SAAO;EACP,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,oCAAoC;EACpC,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".filter-selector-container {\n  margin-bottom: 20px;\n  text-align: center;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n}\n\n.filter-title {\n  margin: 0 10px;\n  flex: 1;\n  text-align: left;\n}\n\n.filter-selector-container select {\n  padding: 5px 10px;\n  font-size: 16px;\n  background-color: #ffffff !important;\n  border: none;\n  border-radius: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
