// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(115, 131, 158, 0.2) transparent;
  width: 100%;
  max-height: 38vh;
  background-color: transparent;
}

.table-wrapper {
  width: 100%;
  border-collapse: collapse;
  font-size: 1em;
}

.table-wrapper th, .table-wrapper td {
  padding: 2vh 3vw;
  text-align: center;
  font-size: 0.9em;
}

.table-wrapper th {
  position: sticky;
  top: 0;
  background-color: #252B35;
  z-index: 1;
}

.even-row {
  background-color: transparent;
  font-size: 1em;
  font-weight: bold;
}

.odd-row {
  background-color: #252B35;
  font-size: 1em;
  font-weight: bold;
}

@media (max-width: 992px) {
  .table-wrapper th, .table-wrapper td {
    font-size: 0.8em;
  }
}

@media (max-width: 768px) {
  .table-container {
    max-height: 32vh;
  }

  .table-wrapper th, .table-wrapper td {
    font-size: 0.7em;
    padding: 2vh 2vw;
  }
}

@media (max-width: 576px) {
  .table-wrapper th, .table-wrapper td {
    font-size: 0.6em;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Tableau.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,gBAAgB;EAChB,qBAAqB;EACrB,qDAAqD;EACrD,WAAW;EACX,gBAAgB;EAChB,6BAA6B;AAC/B;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,MAAM;EACN,yBAAyB;EACzB,UAAU;AACZ;;AAEA;EACE,6BAA6B;EAC7B,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;IAChB,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".table-container {\n  display: flex;\n  flex-direction: column;\n  overflow-y: auto;\n  overflow-x: auto;\n  scrollbar-width: thin;\n  scrollbar-color: rgba(115, 131, 158, 0.2) transparent;\n  width: 100%;\n  max-height: 38vh;\n  background-color: transparent;\n}\n\n.table-wrapper {\n  width: 100%;\n  border-collapse: collapse;\n  font-size: 1em;\n}\n\n.table-wrapper th, .table-wrapper td {\n  padding: 2vh 3vw;\n  text-align: center;\n  font-size: 0.9em;\n}\n\n.table-wrapper th {\n  position: sticky;\n  top: 0;\n  background-color: #252B35;\n  z-index: 1;\n}\n\n.even-row {\n  background-color: transparent;\n  font-size: 1em;\n  font-weight: bold;\n}\n\n.odd-row {\n  background-color: #252B35;\n  font-size: 1em;\n  font-weight: bold;\n}\n\n@media (max-width: 992px) {\n  .table-wrapper th, .table-wrapper td {\n    font-size: 0.8em;\n  }\n}\n\n@media (max-width: 768px) {\n  .table-container {\n    max-height: 32vh;\n  }\n\n  .table-wrapper th, .table-wrapper td {\n    font-size: 0.7em;\n    padding: 2vh 2vw;\n  }\n}\n\n@media (max-width: 576px) {\n  .table-wrapper th, .table-wrapper td {\n    font-size: 0.6em;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
