// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ticket-input {
  padding: 10px;
  border: none;
  border-radius: 20px;
  background-color: #4C566A;
  color: #D8DEE9;
  font-size: 16px;
}

.ticket-input::placeholder {
  color: #adadad;
}`, "",{"version":3,"sources":["webpack://./src/components/Tickets/TicketInput.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EACzB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".ticket-input {\n  padding: 10px;\n  border: none;\n  border-radius: 20px;\n  background-color: #4C566A;\n  color: #D8DEE9;\n  font-size: 16px;\n}\n\n.ticket-input::placeholder {\n  color: #adadad;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
