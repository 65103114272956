// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rond-button {
  width: 55px;
  padding: 10px;
  border: none;
  border-radius: 15px;
  background-color: #7C90AF;
  color: #D9D9D9;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rond-button:hover {
  background-color: #5E81AC;
}`, "",{"version":3,"sources":["webpack://./src/components/Buttons/SubmitButton.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EACzB,cAAc;EACd,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".rond-button {\n  width: 55px;\n  padding: 10px;\n  border: none;\n  border-radius: 15px;\n  background-color: #7C90AF;\n  color: #D9D9D9;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.rond-button:hover {\n  background-color: #5E81AC;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
