// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-header {
  width: 100%;
  color: #fff;
}

.nav-button {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}

.nav-button:hover {
  color: #f7a69a;
}`, "",{"version":3,"sources":["webpack://./src/components/Stats/StatsGraph.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,WAAW;AACb;;AAEA;EACE,6BAA6B;EAC7B,YAAY;EACZ,WAAW;EACX,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".filter-header {\n  width: 100%;\n  color: #fff;\n}\n\n.nav-button {\n  background-color: transparent;\n  border: none;\n  color: #fff;\n  font-size: 24px;\n  cursor: pointer;\n}\n\n.nav-button:hover {\n  color: #f7a69a;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
